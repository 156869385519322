import { MenuItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IoCopyOutline } from 'react-icons/io5';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useToast } from '@/hooks/useToast';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';
import { getLink } from './lib';

type Props = {
  entry: DecoratedEntry;
  instance?: DecoratedInstance;
  isDisabled?: boolean;
  onClick?: () => void;
};

export const CopyOption = ({ entry, instance, isDisabled, onClick }: Props) => {
  const { t } = useTranslation('schedule', {
    keyPrefix: 'copy_link',
  });
  const toast = useToast();
  const { trackEvent } = useAnalytics();
  const { schedule } = useScheduleContext();

  const handleClick = async () => {
    if (!schedule) {
      return;
    }

    try {
      await navigator.clipboard.writeText(getLink(schedule, entry, instance));

      toast.notify(t('copy_success'));

      trackEvent('schedule:click copy-link', {
        entryId: entry.id,
        scheduleId: schedule.id,
      });
    } catch (err) {
      toast.error({
        description: t('copy_failed_description'),
      });
    }

    onClick?.();
  };

  return (
    <MenuItem
      icon={<IoCopyOutline size="18" />}
      isDisabled={isDisabled}
      onClick={handleClick}
    >
      {t('copy_button')}
    </MenuItem>
  );
};
