import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMoreHorizontal } from 'react-icons/fi';
import { HiPencil } from 'react-icons/hi';
import { IoTrashOutline } from 'react-icons/io5';
import { useItemCardContext } from '../../context/useItemCardContext';
import { CopyOption } from './CopyOption';

type Props = {
  onDelete?: () => void;
  onEdit?: () => void;
};

export const MoreMenu = ({ onDelete, onEdit }: Props) => {
  const { entry, instance } = useItemCardContext();
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const { t } = useTranslation('schedule', {
    keyPrefix: 'agenda_view.more_menu',
  });

  return (
    <Menu
      closeOnSelect={false}
      isLazy
      placement="bottom-end"
      onClose={() => setIsConfirmingDelete(false)}
    >
      {({ onClose }) => (
        <>
          <MenuButton
            aria-label={t('aria')}
            as={IconButton}
            colorScheme="dark"
            icon={<FiMoreHorizontal size="20" />}
            size="sm"
            variant="ghost"
          />
          <Portal>
            <MenuList minW="175px">
              {onEdit && (
                <MenuItem
                  icon={<HiPencil size="18" />}
                  isDisabled={isConfirmingDelete}
                  onClick={() => {
                    onEdit();
                    onClose();
                  }}
                >
                  {t('edit')}
                </MenuItem>
              )}
              <CopyOption
                entry={entry}
                instance={instance}
                isDisabled={isConfirmingDelete}
                onClick={onClose}
              />
              {onDelete && (
                <MenuItem
                  icon={<IoTrashOutline size="18" />}
                  onClick={onDelete}
                >
                  {t('delete.delete')}
                </MenuItem>
              )}
            </MenuList>
          </Portal>
        </>
      )}
    </Menu>
  );
};
