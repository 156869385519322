import { config } from '@/config';
import { DecoratedSchedule } from '@/pages/Schedule/contexts';
import { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';
import { getScheduleSlug } from '@/utils/schedule';

export const getLink = (
  schedule: DecoratedSchedule,
  entry: DecoratedEntry,
  instance?: DecoratedInstance
): string => {
  const query = new URLSearchParams({
    utm_source: 'schedule',
    utm_campaign: 'copy-link',
  });

  const path = ['schedule', getScheduleSlug(schedule), 'item', entry.id];
  if (instance) {
    path.push(instance.id);
  }

  return `${config.server.url}/${path.join('/')}?${query}`;
};
